
import { defineComponent } from 'vue'
import Button from '@/components/UI/Button.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter()  
    const goToDashboard = () => {
      router.push({ name: 'Dashboard' })
    }
    return { goToDashboard }
  },
  components: {
    Button  
  }
})
